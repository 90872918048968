body.login {
	background: #000;
	h1 {
		a {
			width: 300px;
			height: 77px;
			background: url(../img/logo.png) no-repeat center center / contain;
		}
	}
	form {
		box-shadow: none;
	}
}

#login {
	background: #000;
	box-shadow: none;
}

.login #backtoblog a, .login #nav a {
	color: #FFF;
	&:hover {
		color: #ff8d33;
	}
}